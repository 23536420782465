import React, { useCallback, useEffect, useState } from 'react';
import { has, allPass, map } from 'ramda';
import Page from '../components/Page.component';
import { XyzTransition } from '@animxyz/react';
import { useParams } from 'react-router-dom';
import QuestionIntro from '../components/questions/company/QuestionIntro.component';
import { ICompany } from '../interface/ICompany';
import api from '../api/api';
import StarQuestion from '../components/StarQuestion.component';
import QuestionLocality from '../components/questions/company/QuestionLocality.component';
import QuestionProsAndCons from '../components/questions/company/QuestionProsAndCons.component';
import AnimatedFrame from '../components/AnimatedFrame.component';
import { generateRandomString } from '../utils/generateRandomString';
import VerificationIntro from '../components/questions/company/VerificationIntro.component';
import VerificationType from '../interface/VerificationType';
import Verification from '../components/questions/company/Verification.component';
import Subscription from '../components/questions/company/Subscription.component';

interface RouteParams extends Record<string, any> {
  id: string;
}

function resolveEnpsOrIco(id: string): {
  enpsId?: string;
  ico?: string;
} {
  const parsedDashUrl = id.split('-');
  if (parsedDashUrl.length > 1 && parsedDashUrl?.pop()?.length === 8) {
    return { enpsId: id };
  } else if (id.length === 8) {
    return { ico: id };
  }

  throw new Error('Unrecognized id format');
}

const token = generateRandomString(20);

function isComplete(result: Record<string, any>): boolean {
  const questionIds = [
    'isFormer',
    'answers[questionOne]',
    'answers[questionTwo]',
    'answers[questionThree]',
    'answers[questionFour]',
    'answers[questionFive]',
    'answers[questionSixA][]',
    'answers[questionSixB][]',
    'answers[questionSixC]',
    'answers[questionSeven]',
    'answers[questionEight]',
  ];
  return allPass(map((questionId: string) => has(questionId), questionIds))(
    result
  );
}

function FoundCompany() {
  const { id = '' } = useParams<RouteParams>();
  let enpsId: string | undefined;
  let ico: string | undefined;
  let initialError: string | null = null;

  try {
    const enpsOrIco = resolveEnpsOrIco(id);
    enpsId = enpsOrIco.enpsId;
    ico = enpsOrIco.ico;
  } catch (e: any) {
    initialError = e.message;
  }

  const [result, setResult] = useState<Record<string, any>>({
    isFormer: false,
    ico,
    enpsId,
    token,
  });
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState<string | null>(initialError);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [phase, setPhase] = useState(0);
  const [oldPhase, setOldPhase] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [verificationType, setVerificationType] =
    useState<VerificationType | null>(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const data = ico
          ? await api.getCompanyByIco(ico)
          : enpsId
            ? await api.getCompanyByEnpsId(enpsId)
            : null;

        if (!data) {
          throw new Error('Id společnosti nebylo rozpoznáno');
        }

        return data;
      } catch (err) {
        throw new Error('Nepodařilo se načíst společnost');
      }
    };

    setLoading(true);

    fetchCompany()
      .then((company) => {
        setCompany(company);
      })
      .catch((e) => {
        setPageError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (isComplete(result)) {
      api
        .postEnps({
          ...result,
          companyName: company?.name,
          companyId: company?.id ?? null,
        })
        .catch((err) => {
          console.error('error', err);
          alert(
            'Při odeslání odpovědi došlo k chybě. Zkuste to prosím znovu za chvilku.'
          );
        });
    }
  }, [result]);

  const updateValue = useCallback((name: any, value: any) => {
    setResult((prev) => ({ ...prev, [name]: value }));
  }, []);

  const updatePhase = (oldPhase: number, newPhase: number) => {
    setOldPhase(oldPhase);
    setPhase(newPhase);
    setAnimating(true);
    setTimeout(() => {
      setAnimating(false);
    }, 500);
  };

  const onNext = useCallback(
    (name: any | null, value: any) => {
      if (name !== null) {
        updateValue(name, value);
      }

      updatePhase(phase, phase + 1);
    },
    [phase]
  );

  const onVerificationNext = useCallback(
    (value: VerificationType) => {
      setVerificationType(value);
      updatePhase(phase, phase + 1);
    },
    [phase]
  );

  if (loading)
    return (
      <Page>
        <XyzTransition appear xyz="fade up">
          <p className="h2 fw-light">Probíhá načítání...</p>
        </XyzTransition>
      </Page>
    );
  if (pageError || !company)
    return (
      <Page>
        <XyzTransition appear xyz="fade up">
          <div className="w-100 m-auto pt-5">
            <p className="h2">
              <span className="border-end border-3 px-2">404</span>
              <span className="fw-lighter">Stránka nebyla nalezena</span>
            </p>
          </div>
        </XyzTransition>
      </Page>
    );

  return (
    <Page>
      <XyzTransition appear xyz="fade up">
        <div id="company-detail">
          <div>
            <p className="mt-5 h3">{company.name}</p>
            <hr className="my-5" />
          </div>

          <XyzTransition appear xyz="fade origin-top ease-in-out">
            <div id="wizard" className="position-relative">
              <AnimatedFrame
                myKey={0}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <QuestionIntro onNext={onNext} isDirectLink={!!enpsId} />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={1}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionOne]"
                  label="1/10 Zahřívací otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) Českou republiku jako místo pro život svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={2}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionOneB]"
                  label="2/10 Zahřívací otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) váš kraj jako místo pro život svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={3}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionOneB]"
                  label="3/10 Zahřívací otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) váš kraj jako místo pro život svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={4}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <QuestionLocality
                  name="answers[questionOneC]"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={5}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionTwo]"
                  label="4/10 Čtvrtá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) pracovat pro vašeho zaměstnavatele svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={6}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionThree]"
                  label="5/10 Pátá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) produkty/služby vašeho zaměstnavatele svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={7}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionFour]"
                  label="6/10 Šestá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) pracovat pro vašeho šéfa (přímého nadřízeného) svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={8}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionFive]"
                  label="7/10 Sedmá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) pracovat ve vašem pracovním týmu svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={9}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionSeven]"
                  label="8/10 Osmá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) vzdělávání u vašeho zaměstnavatele svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={10}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <StarQuestion
                  name="answers[questionEight]"
                  label="9/10 Devátá otázka"
                  question="Do jaké míry je pravděpodobné, že byste doporučil(a) vašeho zaměstnavatele jako společensky odpovědného zaměstnavatele svým přátelům a známým?"
                  onNext={onNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={11}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <QuestionProsAndCons
                  onNext={onNext}
                  updateValue={updateValue}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={12}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <VerificationIntro
                  isFormer={result.isFormer}
                  onNext={onVerificationNext}
                />
              </AnimatedFrame>
              <AnimatedFrame
                myKey={13}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                {verificationType && (
                  <Verification
                    verificationType={verificationType}
                    onNext={onNext}
                  />
                )}
              </AnimatedFrame>
              <AnimatedFrame
                myKey={14}
                currentKey={phase}
                oldKey={oldPhase}
                animating={animating}
              >
                <Subscription token={token} needVerification={!!result.email} />
              </AnimatedFrame>
            </div>
          </XyzTransition>
        </div>
      </XyzTransition>
    </Page>
  );
}

export default FoundCompany;
