import React from 'react';
import VerificationType from '../../../interface/VerificationType';
import VerificationEmail from './VerificationEmail.component';
import VerificationFile from './VerificationFile.component';

interface VerificationProps {
  verificationType: VerificationType;
  onNext: (name: any, value: any) => void;
}

function Verification({ verificationType, onNext }: VerificationProps) {
  if (verificationType === VerificationType.email) {
    return <VerificationEmail onNext={onNext} />;
  }

  if (verificationType === VerificationType.file) {
    return <VerificationFile onNext={onNext} />;
  }

  return <></>;
}

export default Verification;
