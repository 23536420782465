import React, { useCallback, useState } from 'react';
import { trim } from 'ramda';

import ContestDetails from '../../ContestDetails.component';
import ContestConditions from '../../ContestConditions.component';
import ErrorMessage from '../../ErrorMessage.component';
import api from '../../../api/api';

interface SubscriptionProps {
  token: string;
  needVerification: boolean;
}

interface CompletedResult {
  completed: boolean;
  alreadySubscribed?: boolean;
}

function Subscription({ token, needVerification }: SubscriptionProps) {
  const [completed, setCompleted] = useState<CompletedResult | null>(null);
  const [result, setResult] = useState<Record<string, any>>({
    email: '',
    referenceId: token,
    referenceType: 'enpsToken',
    contestAnswer: null,
    isContestSubscription: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [consent, setConsent] = useState(false);

  const onSubmit = useCallback(() => {
    if (result.email === '') {
      setError('Vyplňte prosím e-mailovou adresu.');
      return;
    }

    if (!consent) {
      setError('Musíte souhlasit se zpracováním osobních údajů.');
      return;
    }

    if (result.isContestSubscription && result.contestAnswer === null) {
      setError('Vyplňte prosím odhadovaný počet respondentů.');
      return;
    }

    api
      .postResultSubscription(result)
      .then((newSubscriptionCreated) => {
        if (newSubscriptionCreated) {
          setCompleted({
            completed: true,
            alreadySubscribed: true,
          });
        } else {
          setCompleted({
            completed: true,
          });
        }
      })
      .catch(() => {
        setError(
          'Při registraci emailu k odběru výsledků průzkumu došlo k chybě. Zkuste to prosím znovu za chvilku.'
        );
      });
  }, [result]);

  if (completed && completed.alreadySubscribed) {
    return (
      <p>
        E-mail <strong>{result.email}</strong> je již k odběru registrován.
      </p>
    );
  }

  if (completed) {
    return (
      <div>
        <p>
          Vaše e-mailová adresa <strong>{result.email}</strong> byla
          zaregistrována.
        </p>
        <p>
          Nezapomeňte sdílet odkaz{' '}
          <a href="https://sefroku.cz" target="_blank">
            SEFroku.cz
          </a>
          , pokud se účastníte soutěže.
        </p>
        <p>Nyní můžete stránku zavřít.</p>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-3">
        <p>Děkujeme za hlasování v průzkumu.</p>
        {needVerification ? (
          <div>
            <p>
              Nyní už zbývá jen ověřit vaši e-mailovou adresu.{' '}
              <strong>
                Zkontrolujte prosím vaši e-mailovou schránku a řiďte se
                instrukcemi.
              </strong>
            </p>
          </div>
        ) : (
          <p>Nyní se můžete zapojit do soutěže.</p>
        )}
        <div className="row">
          <div className="col-12">
            <div className="form-check mb-3">
              <input
                type="checkbox"
                name="contest-email-check"
                className="form-check-input"
                onChange={(e) =>
                  setResult((v) => ({
                    ...v,
                    isContestSubscription: e.target.checked,
                  }))
                }
              />
              <label htmlFor="contest-email-check" className="form-check-label">
                Chci se zapojit do soutěže o dovolenou snů v Egyptě (týdenní
                letecký zájezd do Egypta pro 2 osoby v 5* hotelu all inclusive
                přímo u pláže)
              </label>
            </div>
          </div>
        </div>

        {result.isContestSubscription && (
          <div className="row mb-3">
            <label htmlFor="contest-question" className="form-label col-sm-8">
              Kolik odhadujete, že se letos do našeho průzkumu zapojí
              respondentů?
            </label>
            <div className="col-sm-4">
              <input
                type="number"
                name="subscribe-email-check"
                className="form-control"
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) ?? null;
                  setResult((v) => ({
                    ...v,
                    contestAnswer: isNaN(value) ? null : value,
                  }));
                }}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <div className="form-check mb-3">
              <input
                type="checkbox"
                name="consent-email-check"
                className="form-check-input"
                onChange={(e) => setConsent(e.target.checked)}
              />
              <label htmlFor="consent-email-check" className="form-check-label">
                Seznámil jsem se s{' '}
                <a
                  href="https://absolventi.cz/zasady-ochrany-soukromi"
                  target="_blank"
                >
                  Podmínkami zpracování osobních údajů
                </a>{' '}
                a{' '}
                <a
                  href="https://absolventi.cz/souhlas-se-zpracovanim-ou"
                  target="_blank"
                >
                  souhlasím se zpracováním
                </a>
                .
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <input
              type="email"
              name="subscribe-email"
              placeholder="E-mailová adresa"
              onChange={(e) =>
                setResult((v) => ({
                  ...v,
                  email: trim(e.target.value),
                }))
              }
              className="form-control"
              aria-placeholder="E-mailová adresa"
            />
          </div>
          <div className="col-md-4 pt-3 pt-md-0">
            <button
              type="submit"
              className="btn btn-primary btn-block"
              onClick={onSubmit}
            >
              <span className="pe-3">Zaslat</span>
              <i className="fas fa-envelope"></i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-muted small">
            Výsledky vám zašleme po ukončení průzkumu na výše uvedený e-mail.
          </div>
        </div>
        {error && <ErrorMessage message={error} />}
      </div>

      <hr className="my-5" />

      <div className="row mb-3 small">
        <div className="col-12">
          <ContestDetails />
        </div>
      </div>

      <div className="row mb-3 small">
        <div className="col-12">
          <ContestConditions />
        </div>
      </div>
    </div>
  );
}

export default Subscription;
