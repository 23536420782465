import React, { useState, useCallback } from 'react';
import { debounce } from '../utils/debounce';

interface AutocompleteSearchProps<T> {
  itemFormatter: ({ item }: { item: T }) => React.ReactNode;
  getEndpoint: (query: string) => string;
}

interface PaginatedResult<T> {
  values: T[];
  results: number;
}

function AutocompleteSearch<T>({
  itemFormatter,
  getEndpoint,
}: AutocompleteSearchProps<T>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [results, setResults] = useState<PaginatedResult<T> | null>(null);

  const searchAPI = async (query: string) => {
    setLoading(true);
    try {
      const response = await fetch(getEndpoint(query));
      const { data } = await response.json();
      setResults(data);
      if (!data.results) {
        setMessage('Nebyly nalezeny žádné výsledky');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearch = useCallback(debounce(searchAPI, 500), []);

  // Handle input change
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const oldValue = message;
      setQuery(value);

      if (!value.length) {
        setMessage('');
        return;
      }

      if (value.length < 3) {
        setMessage('Zadejte alespoň 3 znaky pro vyhledání');
        return;
      }

      if (value !== oldValue) {
        setMessage('');
      }

      debouncedSearch(value);
    },
    [message]
  );

  return (
    <>
      <div className="input-group mb-4">
        <input
          type="search"
          name="q"
          id="q"
          value={query}
          onChange={handleChange}
          placeholder="Zadejte název firmy, kterou hledáte ..."
          className="form-control"
          aria-placeholder="Zadejte název firmy, kterou hledáte"
        />
        <button
          className="btn btn-outline-primary btn-block"
          type="submit"
          id="button-addon2"
        >
          <span className="pe-3 d-none d-sm-inline">Vyhledat</span>
          <i className="fas fa-search"></i>
        </button>
      </div>
      {loading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      <div>{message}</div>
      <div>
        {results && results.results > 0 && (
          <div id="company-list">
            {results.results > results.values.length && (
              <p>
                Nalezeno celkem {results.results} záznamů. Zobrazuji{' '}
                {results.values.length}:
              </p>
            )}
            {results.values?.map((item) => itemFormatter({ item }))}
          </div>
        )}
      </div>
    </>
  );
}

export default AutocompleteSearch;
