import React from 'react';

interface ErrorMessageProps {
  message: string;
}

function ErrorMessage({ message }: ErrorMessageProps) {
  return <div className="alert alert-danger">{message}</div>;
}

export default ErrorMessage;
