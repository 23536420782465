import React from 'react';

import { XyzTransition } from '@animxyz/react';
import '@animxyz/core';

function Navbar() {
  return (
    <XyzTransition appear xyz="fade up delay-2 xyz-in">
      <nav className="navbar navbar-light">
        <div className="container">
          <div className="navbar-brand cursor-pointer m-0 d-flex justify-content-center align-items-center flex-sm-wrap flex-column flex-md-row w-100">
            <img
              onClick={() => window.location.reload()}
              className="text-center text-sm-start my-2 img-fluid"
              src="/images/logo.png"
              alt=""
              width="500"
              height="54"
            />
            <div className="d-flex justify-content-between px-2 align-items-center flex-fill flex-sm-grow-0">
              <a
                href="https://znackaroku.cz"
                target="_blank"
                style={{ width: 90 }}
                className="mx-2"
              >
                <img
                  src="/images/znackaroku.svg"
                  alt="ZnačkaRoku.cz"
                  width="90"
                />
              </a>
              <a
                href="https://sefroku.cz"
                target="_blank"
                className="my-2 mx-2"
                style={{ width: 200 }}
              >
                <img src="/images/sefroku.svg" alt="ŠéfRoku.cz" width="200" />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </XyzTransition>
  );
}

export default Navbar;
