import React from 'react';

function Introduction() {
  return (
    <div id="intro-text" className="mt-4 my-5">
      <p>Dobrý den,</p>
      <p>
        vítejte v&nbsp;krátkém průzkumu, ve kterém mohou jednou ročně všichni
        zaměstnanci všech firem/organizací v&nbsp;ČR ohodnotit spokojenost se
        svým ZAMĚSTNAVATELEM, ŠÉFEM, pracovním TÝMEM, PRODUKTEM/SLUŽBOU
        zaměstnavatele, ale také VZDĚLÁVÁNÍM u&nbsp;zaměstnavatele
        a&nbsp;SPOLEČENSKY ODPOVĚDNÉ CHOVÁNÍ zaměstnavatele.
      </p>
      <p>
        Navazujeme na naší studii TOP Zaměstnavatelé, ve které již přes
        12&nbsp;let mapujeme, kteří zaměstnavatelé jsou vnímáni jako ti nejlepší
        v&nbsp;České republice z&nbsp;pohledu studentů a&nbsp;absolventů.
        V&nbsp;letošním ročníku mohou podruhé hodnotit i&nbsp;zaměstnanci.
      </p>
      <p>
        Průzkum netrvá více než minutu, je zcela anonymní a&nbsp;v&nbsp;rámci
        průzkumu se vás nebudeme ptát na žádné demografické údaje. Zeptáme se
        jen na 1 zahřívací otázku a&nbsp;několik dalších otázek vztahujících se
        k&nbsp;vašemu zaměstnavateli.
      </p>
      <p>
        Celkové výsledky z&nbsp;prvního ročníku má váš zaměstnavatel již k
        dispozici, stejně tak je dostane k&nbsp;dispozici i&nbsp;z&nbsp;tohoto
        druhého ročníku. Pokud budete chtít, můžete na konci průzkumu požádat
        o&nbsp;zaslání výsledků také a&nbsp;zapojit se do soutěže o&nbsp;týdenní
        letecký zájezd do Egypta pro 2 osoby v&nbsp;5*&nbsp;hotelu all inclusive
        přímo u&nbsp;pláže.
      </p>
      <p>Děkujeme za váš čas.</p>
      <p>Tým autorů studie TOP Zaměstnavatelé</p>
    </div>
  );
}

export default Introduction;
