import React, { useCallback, useState } from 'react';
import ErrorMessage from '../../ErrorMessage.component';

interface VerificationFileProps {
  onNext: (name: any, value: any) => void;
}

function VerificationFile({ onNext }: VerificationFileProps) {
  const [file, setFile] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const onClick = useCallback(() => {
    if (file === null) {
      setError('Přiložte prosím soubor.');
      return;
    }
    onNext('file', file);
  }, [file]);

  const onChange = useCallback((e: any) => {
    setError(null);
    setFile(e.target.files[0] || null);
  }, []);

  return (
    <div>
      <div className="mb-3">
        <div>
          <label htmlFor="input-verification-file" className="form-label">
            Doklad o zaměstnání
          </label>
          <input
            name="verificationFile"
            className="form-control"
            type="file"
            onChange={onChange}
            id="input-verification-file"
            accept="image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
          />
        </div>
        <div className="form-text text-muted">
          Vložte dokument, který prokazuje, že u hodnoceného zaměstnavatele
          pracujete nebo jste pracoval/a.
          <br />
          Dokladem může být foto části pracovní smlouvy nebo výplatní pásky,
          foto firemní vizitky atd.
          <br />
          Váš dokument bude smazán ihned po manuálním ověření.
        </div>
      </div>
      {error && <ErrorMessage message={error} />}
      <button
        type="button"
        onClick={onClick}
        className="btn btn-outline-primary"
      >
        <span className="pe-3">Odeslat potvrzení</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

export default VerificationFile;
