import React from 'react';

function ContestDetails() {
  return (
    <>
      <h6>Princip soutěže:</h6>
      <ol>
        <li>Hodnotíš</li>
        <li>Tipuješ počet respondentů</li>
        <li>Sdílíš odkaz na hlasování na jedné ze svých sociálních sítí</li>
        <li>
          Uděláš si fotku nebo snímek obrazovky (printscreen) tvého sdílení na
          síti (když vyhraješ, budeme chtít vidět)
        </li>
        <li>
          Vyhráváš a odlétáš s jednou osobou na týdenní letecký zájezd do Egypta
          pro 2 osoby v 5* hotelu all inclusive přímo u pláže
        </li>
      </ol>
    </>
  );
}

export default ContestDetails;
