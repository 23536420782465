import React from 'react';

function ContestConditions() {
  return (
    <>
      <h6>Pravidla soutěže:</h6>
      <ul>
        <li>
          Výhru v soutěži získává ten, kdo ohodnotí zaměstnavatele, nejlépe
          odhadne počet respondentů v závěrečné otázce, posdílí odkaz na
          hlasování na jedné ze svých sociálních sítí a udělá si fotku nebo
          snímek obrazovky (printscreen) svého sdílení na některé ze svých
          sociálních sítí (v případě výhry budeme ověřovat). Uhodne-li více
          účastníků, vyhrává ten, kdo odpověděl nejdříve (myšleno, kdy byla
          odpověď doručena). Organizátor neodpovídá za technické problémy v
          rámci doručování zpráv. Neuhodne-li nikdo, vyhrává ten, kdo byl
          nejblíže správné odpovědi. Bude-li nejblíže více účastníků, vyhrává
          ten, který byl nejblíže a odpověděl nejdříve (myšleno, kdy byla
          odpověď doručena).
        </li>
        <li>
          Účastník soutěže musí vyplnit celý dotazník. Neúplně vyplněný dotazník
          bude vyloučen ze soutěže.
        </li>
        <li>
          Pokud účastník soutěže vyplní dotazník vícekrát, bude vyřazen ze
          soutěže.
        </li>
        <li>Účast v soutěži, ani výhru nelze jakkoli vymáhat.</li>
        <li>
          Organizátor si vyhrazuje právo kdykoliv změnit či upravit pravidla
          soutěže či soutěž zcela zrušit bez udání důvodu a náhrady.
        </li>
        <li>
          Výherce bude prostřednictvím zadané emailové adresy informován o výhře
          a způsobu jejího předání. Výherce je povinen si výhru převzít do 1
          měsíce ode dne oznámení, v opačném případě výhra propadá. Organizátor
          neodpovídá za vady věci.
        </li>
        <li>
          V případě výhry souhlasí účastník se zveřejněním svého jména a
          fotografie z předání výhry.
        </li>
        <li>
          Vyhodnocení výsledků soutěže a určení výherců bude probíhat v říjnu
          2023. Právní vztahy vyplývající z této soutěže se řídí zejména zákonem
          č. 89/2012 Sb., občanský zákoník a dále zákonem č. 186/2016 Sb., o
          hazardních hrách.
        </li>
      </ul>
    </>
  );
}

export default ContestConditions;
