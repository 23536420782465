import React from 'react';
import { Link } from 'react-router-dom';
import { ICompany } from '../interface/ICompany';

function AutocompleteCompanyItem({ item }: { item: ICompany }) {
  const address = [item.street, item?.locality?.city_name, item.postal]
    .filter(
      (item) => typeof item !== 'undefined' && item !== '' && item !== null
    )
    .join(', ');
  const link = item.ico ? `/${item.ico.replace(/\\s/g, '')}` : null;

  if (!link) {
    return (
      <div className="py-2 border-bottom">
        <h5 className="m-0 text-decoration-underline">{item.name}</h5>
        {address ? `<address class="m-0 text-muted">${address}</address>` : ''}
      </div>
    );
  }

  return (
    <div className="py-2 border-bottom">
      <Link
        className="text-decoration-none"
        to={`/${item.ico.replace(/\\s/g, '')}`}
      >
        <h5 className="m-0 text-decoration-underline">{item.name}</h5>
        {address ? `<address class="m-0 text-muted">${address}</address>` : ''}
      </Link>
    </div>
  );
}

export default AutocompleteCompanyItem;
