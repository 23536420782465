import { split } from 'ramda';

export const generateRandomString = (len: number): string => {
  const allCapsAlpha = split('', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ');
  const allLowerAlpha = split('', 'abcdefghijklmnopqrstuvwxyz');
  const allNumbers = split('', '0123456789');
  const base = [...allCapsAlpha, ...allNumbers, ...allLowerAlpha];

  return [...Array(len)]
    .map(() => base[(Math.random() * base.length) | 0])
    .join('');
};
