import React, { useCallback, useEffect } from 'react';
import Page from '../components/Page.component';
import { XyzTransition } from '@animxyz/react';
import { useParams } from 'react-router-dom';
import api, { VerificationResponseCode } from '../api/api';

interface RouteParams extends Record<string, any> {
  code: string;
}

const ResponseCodeMessage = ({
  responseCode,
}: {
  responseCode: VerificationResponseCode;
}) => {
  switch (responseCode) {
    case VerificationResponseCode.OK:
      return (
        <>
          <p className="h2 fw-light">Adresa byla úspěšně ověřena.</p>
          <p className="h5 fw-light">Děkujeme za hlasování v našem průzkumu.</p>
        </>
      );
    case VerificationResponseCode.ALREADY_VERIFIED:
      return (
        <>
          <p className="h2 fw-light">
            E-mailová adresa již byla v minulosti ověřena.
          </p>
          <p className="h5 fw-light">
            Vše je v pořádku, ještě jednou děkujeme za váš hlas.
          </p>
        </>
      );
    case VerificationResponseCode.NOT_FOUND:
      return (
        <p className="h2 fw-light">
          Nepodařilo se ověřit e-mailovou adresu. Zkontrolujte správnost odkazu.
        </p>
      );
  }
};

function Verify() {
  const { code = '' } = useParams<RouteParams>();
  const [loading, setLoading] = React.useState(true);
  const [verified, setVerified] =
    React.useState<VerificationResponseCode | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const verify = useCallback(() => {
    setLoading(true);
    api
      .postEnpsVerification(code)
      .then((responseCode) => setVerified(responseCode))
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [code]);

  useEffect(() => {
    verify();
  }, [code]);

  return (
    <Page>
      <XyzTransition appear xyz="fade up">
        {loading && (
          <p className="h2 fw-light">Právě probíhá ověření e-mailové adresy</p>
        )}
        {!loading && error && (
          <div>
            <p className="h2 fw-light">
              Nepodařilo se ověřit e-mailovou adresu.
              <br />
              Zkuste to prosím znovu později.
            </p>
            <button
              id="verify-again"
              type="button"
              className="btn btn-primary btn-block mt-3"
              onClick={verify}
            >
              <span className="pe-3">Zkusit znovu</span>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        )}
        {!loading && verified && (
          <ResponseCodeMessage responseCode={verified} />
        )}
      </XyzTransition>
    </Page>
  );
}

export default Verify;
