import React, { useCallback, useState } from 'react';
import ErrorMessage from '../../ErrorMessage.component';

interface VerificationEmailProps {
  onNext: (name: any, value: any) => void;
}

function VerificationEmail({ onNext }: VerificationEmailProps) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);

  const onClick = useCallback(() => {
    if (email == '') {
      setError('Vyplňte prosím email.');
      return;
    }
    onNext('email', email);
  }, [email]);

  const onChange = useCallback((e: any) => {
    setError(null);
    setEmail(e.target.value || '');
  }, []);

  return (
    <div>
      <div className="mb-3">
        <label htmlFor="input-verification-email" className="form-label">
          Firemní e-mail
        </label>
        <input
          name="email"
          type="email"
          className="form-control"
          id="input-verification-email"
          onChange={onChange}
          aria-describedby="Emailová adresa"
          placeholder="Vložte váš firemní email, abychom mohli ověřit, že pro firmu pracujete"
        />
        <div className="form-text text-muted">
          Potvrzení slouží pouze pro ověření toho, že jste zaměstnanec hodnocené
          firmy a není nijak spojeno s vaším hodnocením, které tak zůstává zcela
          anonymní.
          <br />
          Na váš email vám během hlasování pošleme odkaz k potvrzení vaší
          emailové adresy.
          <br />
          Váš email bude smazán v den ukončení hlasování.
        </div>
      </div>
      {error && <ErrorMessage message={error} />}
      <button
        type="button"
        onClick={onClick}
        className="btn btn-outline-primary"
      >
        <span className="pe-3">Odeslat potvrzení</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

export default VerificationEmail;
