import React from 'react';
import Page from '../components/Page.component';
import { XyzTransition } from '@animxyz/react';
import '@animxyz/core';

function End() {
  return (
    <Page>
      <XyzTransition appear xyz="fade up">
        <div id="wrapper" className="container">
          <p className="h3 mt-4 fw-light">Průzkum je uzavřen.</p>
          <p className="mt-4">
            Děkujeme vám za váš zájem, dostupné výsledky jsou zveřejněny na{' '}
            <a href="https://skariera.cz" target="_blank">
              SKARIERA.cz
            </a>{' '}
            v profilech jednotlivých zaměstnavatelů.
          </p>
          <p className="mt-4">
            Pro případné dotazy nás neváhejte kontaktovat na{' '}
            <a href="mailto:hodnoceni@topzamestavatele.cz">
              hodnoceni@topzamestavatele.cz
            </a>
          </p>
          <p className="mt-4">Tým TOP Zaměstnavatelé</p>
        </div>
      </XyzTransition>
    </Page>
  );
}

export default End;
