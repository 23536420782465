import React from 'react';
import Introduction from '../../Introduction.component';

interface QuestionIntroProps {
  onNext: (name: any, value: any) => void;
  isDirectLink: boolean;
}

function QuestionIntro({ onNext, isDirectLink }: QuestionIntroProps) {
  return isDirectLink ? (
    <div>
      <Introduction />
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => onNext(null, null)}
      >
        <span className="pe-3">Další</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  ) : (
    <div>
      <div className="btn-group" role="group" aria-label="Typ zaměstnance">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => onNext('isFormer', false)}
        >
          Pro firmu pracuji
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => onNext('isFormer', true)}
        >
          Jsem bývalý zaměstnanec
        </button>
      </div>
    </div>
  );
}

export default QuestionIntro;
