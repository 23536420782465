import React, { useCallback, useState } from 'react';
import { assocPath, trim, reject, isEmpty } from 'ramda';

interface QuestionProsAndConsProps {
  onNext: (name: any, value: any) => void;
  updateValue: (name: any, value: any) => void;
}

interface QuestionProsAndConsState {
  questionSixA: string[];
  questionSixB: string[];
  questionSixC: string;
}

function QuestionProsAndCons({
  onNext,
  updateValue,
}: QuestionProsAndConsProps) {
  const [values, setValues] = useState<QuestionProsAndConsState>({
    questionSixA: ['', '', ''],
    questionSixB: ['', '', ''],
    questionSixC: '',
  });

  const onChange = useCallback((path: (string | number)[], e: any) => {
    setValues((prev) => {
      const value = trim(e.target.value);
      return assocPath(path, value, prev);
    });
  }, []);

  const onClick = useCallback(() => {
    updateValue(
      'answers[questionSixA][]',
      reject(isEmpty)(values.questionSixA)
    );
    updateValue(
      'answers[questionSixB][]',
      reject(isEmpty)(values.questionSixB)
    );
    updateValue('answers[questionSixC]', values.questionSixC);
    onNext(null, null);
  }, [onNext, values]);

  return (
    <div>
      <p className="mb-3">10/10 Závěrečná otázka</p>
      <p className="mb-0 fw-bold">Napište 3 plusy společnosti</p>
      <div className="mb-4">
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixA][]"
          onChange={(e) => onChange(['answers[questionSixA][]', 0], e)}
          rows={1}
        ></textarea>
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixA][]"
          onChange={(e) => onChange(['answers[questionSixA][]', 1], e)}
          rows={1}
        ></textarea>
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixA][]"
          onChange={(e) => onChange(['answers[questionSixA][]', 2], e)}
          rows={1}
        ></textarea>
      </div>
      <p className="mb-0 fw-bold">Napište 3 mínusy společnosti</p>
      <div className="mb-4">
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixB][]"
          onChange={(e) => onChange(['answers[questionSixB][]', 0], e)}
          rows={1}
        ></textarea>
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixB][]"
          onChange={(e) => onChange(['answers[questionSixB][]', 1], e)}
          rows={1}
        ></textarea>
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixB][]"
          onChange={(e) => onChange(['answers[questionSixB][]', 2], e)}
          rows={1}
        ></textarea>
      </div>
      <p className="mb-0 fw-bold">Co byste doporučili vedení společnosti?</p>
      <div className="mb-4">
        <textarea
          className="mt-2 form-control"
          name="answers[questionSixC]"
          onChange={(e) => onChange(['answers[questionSixC]'], e)}
          rows={3}
        ></textarea>
      </div>
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={onClick}
      >
        <span className="pe-3">Další</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

export default QuestionProsAndCons;
