import React from 'react';

import VerificationType from '../../../interface/VerificationType';

interface VerificationIntroProps {
  onNext: (value: VerificationType) => void;
  isFormer: boolean;
}

function VerificationIntro({ onNext, isFormer }: VerificationIntroProps) {
  return isFormer ? (
    <div>
      <p>Ověření, že jste bývalý zaměstnanec</p>
      <div className="btn-group mt-3" role="group" aria-label="Typ ověření">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => onNext(VerificationType.file)}
        >
          Doklad o zaměstnání
        </button>
      </div>
      <p className="mt-3">
        Dokladem o zaměstnání je myšlen takový dokument, který prokazuje, že u
        hodnoceného zaměstnavatele pracujete nebo jste pracoval/a.
      </p>
    </div>
  ) : (
    <div>
      <p>Ověření, že pro firmu pracujete</p>
      <div className="btn-group mt-3" role="group" aria-label="Typ ověření">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => onNext(VerificationType.email)}
        >
          Firemní email
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => onNext(VerificationType.file)}
        >
          Doklad o zaměstnání
        </button>
      </div>
    </div>
  );
}

export default VerificationIntro;
