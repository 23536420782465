import React from 'react';
import { XyzTransition } from '@animxyz/react';

interface AnimatedFrameProps {
  myKey: number;
  currentKey: number;
  oldKey: number;
  animating: boolean;
  children: React.ReactNode;
}

function AnimatedFrame({
  children,
  animating,
  oldKey,
  currentKey,
  myKey,
}: AnimatedFrameProps) {
  const isInitial = oldKey === currentKey && myKey === currentKey;
  const isAnimatingIn =
    oldKey !== currentKey && !animating && myKey === currentKey;
  const isAnimatingOut = animating && myKey === oldKey;
  const shouldRender = isAnimatingIn || isAnimatingOut || isInitial;

  return (
    <div
      className={`${!shouldRender ? 'hidden' : ''} ${animating ? 'position-absolute' : ''}`}
    >
      <XyzTransition appear xyz="ease-in-out fade out-left-50% in-right-50%">
        <div
          className={isAnimatingIn ? 'xyz-in' : isAnimatingOut ? 'xyz-out' : ''}
        >
          {children}
        </div>
      </XyzTransition>
    </div>
  );
}

export default AnimatedFrame;
