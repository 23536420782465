import React, { useCallback, useState } from 'react';
import ErrorMessage from '../../ErrorMessage.component';

interface QuestionLocalityProps {
  name: string;
  onNext: (name: any, value: any) => void;
}

function QuestionLocality({ name, onNext }: QuestionLocalityProps) {
  const [locality, setLocality] = useState(null);
  const [error, setError] = useState<string | null>(null);

  const onClick = useCallback(() => {
    if (locality === null) {
      setError('Vyplňte prosím kraj.');
      return;
    }
    onNext(name, locality);
  }, [name, onNext, locality]);

  const onChange = useCallback((e: any) => {
    setError(null);
    setLocality(e.target.value || null);
  }, []);

  return (
    <div>
      <p className="mb-3">3/10 Zahřívací otázka</p>
      <p className="mb-0 fw-bold">Ve kterém kraji bydlíte?</p>
      <div className="mb-4 mt-2">
        <select name={name} className="form-select" onChange={onChange}>
          <option value="">- vyberte kraj -</option>
          <option value="praha">Hlavní město Praha</option>
          <option value="jihocesky">Jihočeský kraj</option>
          <option value="jihomoravsky">Jihomoravský kraj</option>
          <option value="karlovarsky">Karlovarský kraj</option>
          <option value="vysocina">Kraj Vysočina</option>
          <option value="kralovehradecky">Královéhradecký kraj</option>
          <option value="liberecky">Liberecký kraj</option>
          <option value="moravskoslezsky">Moravskoslezský kraj</option>
          <option value="olomoucky">Olomoucký kraj</option>
          <option value="pardubicky">Pardubický kraj</option>
          <option value="plzensky">Plzeňský kraj</option>
          <option value="stredocesky">Středočeský kraj</option>
          <option value="ustecky">Ústecký kraj</option>
          <option value="zlinsky">Zlínský kraj</option>
        </select>
      </div>
      {error && <ErrorMessage message={error} />}
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={onClick}
      >
        <span className="pe-3">Další</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

export default QuestionLocality;
