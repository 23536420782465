import React, { useCallback, useState } from 'react';

import StarRating from './StarRating.component';
import ErrorMessage from './ErrorMessage.component';

interface StarQuestionProps {
  onNext: (name: any, value: any) => void;
  label: string;
  question: string;
  name: string;
}

function StarQuestion({ label, name, question, onNext }: StarQuestionProps) {
  const [rating, setRating] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onClick = useCallback(() => {
    if (rating === null) {
      setError('Vyplňte prosím hodnocení.');
      return;
    }
    onNext(name, rating);
  }, [name, onNext, rating]);

  const onChange = useCallback((index: number) => {
    setError(null);
    setRating(index);
  }, []);

  return (
    <div>
      <p className="mb-3">{label}</p>
      <p className="mb-0 fw-bold">{question}</p>
      <div className="mb-5 mt-3">
        <StarRating
          numberOfStars={11}
          onChange={onChange}
          value={rating}
          starSettings={[{ color: '#f9ab06', emotion: 'neutral' }]}
        />
      </div>
      {error && <ErrorMessage message={error} />}
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={onClick}
      >
        <span className="pe-3">Další</span>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
}

export default StarQuestion;
