import React from 'react';
import { XyzTransition } from '@animxyz/react';
import '@animxyz/core';
import Page from '../components/Page.component';
import AutocompleteSearch from '../components/AutocompleteSearch.component';
import AutocompleteCompanyItem from '../components/AutocompleteCompanyItem.component';
import Introduction from '../components/Introduction.component';
import { ICompany } from '../interface/ICompany';

function Home() {
  return (
    <Page>
      <XyzTransition appear xyz="fade up">
        <div>
          <Introduction />
          <p className="mt-3 h1">Vyhledat zaměstnavatele</p>
          <p className="lead">
            Pro hodnocení vašeho zaměstnavatele jej nejdříve vyhledejte podle
            jeho oficiálního názvu nebo zadejte jeho IČO:
          </p>

          <AutocompleteSearch<ICompany>
            itemFormatter={({ item }) => (
              <AutocompleteCompanyItem item={item} key={item.id} />
            )}
            getEndpoint={(query: string) =>
              `https://dev.absolventi.cz/fulltext/companies/?text=${query}&limit=20&page=0`
            }
          />
        </div>
      </XyzTransition>
    </Page>
  );
}

export default Home;
