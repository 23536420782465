import React, { useEffect, useState } from 'react';

import styles from './StarRating.module.scss';

interface StarProps {
  index: number;
  selected: boolean;
  hovered: boolean;
  isHovered: boolean;
  onClick: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onMouseLeave: () => void;
  color: string;
}

const Star: React.FC<StarProps> = ({
  index,
  selected,
  hovered,
  isHovered,
  onClick,
  onMouseEnter,
  onMouseLeave,
  color,
}) => {
  const isActive = isHovered ? hovered : selected;
  const starStyle = {
    '--color': color, // Set the custom color
    color: isActive ? color : '#fff',
  } as React.CSSProperties;

  const numberStyle = isActive
    ? {
        color: '#fff',
      }
    : {};

  return (
    <div
      style={starStyle}
      className={`${styles.starContainer}`}
      onClick={() => onClick(index)}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={onMouseLeave}
    >
      <svg
        className={`${styles.star} ${isActive ? styles.selected : ''} p-1 p-md-2`}
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="star"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        data-fa-i2svg=""
      >
        <path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
      </svg>
      <span
        className={`${styles.starNumber} fs-sm-6 fs-md-4`}
        style={numberStyle}
      >
        {index}
      </span>
    </div>
  );
};

interface StarSettings {
  color: string;
  emotion: 'positive' | 'neutral' | 'negative';
}

interface StarRatingProps {
  numberOfStars: number;
  value?: number | null;
  starSettings: StarSettings[];
  onChange: (index: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({
  numberOfStars,
  starSettings,
  onChange,
  value,
}) => {
  const [selectedStar, setSelectedStar] = useState<number | null>(null);
  const [hoveredStar, setHoveredStar] = useState<number | null>(null);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setSelectedStar(value);
    }
  }, [value]);

  const handleClick = (index: number) => {
    setSelectedStar(index);
    onChange(index);
  };

  const handleMouseEnter = (index: number) => {
    setHoveredStar(index);
  };

  const handleMouseLeave = () => {
    setHoveredStar(null);
  };

  return (
    <div className="d-flex">
      <div className="d-flex flex-column">
        <div className={`${styles.container}`}>
          {Array.from({ length: numberOfStars }, (_, index) => (
            <div key={index}>
              <Star
                index={index}
                selected={selectedStar !== null && index <= selectedStar}
                hovered={hoveredStar !== null && index <= hoveredStar}
                isHovered={hoveredStar !== null}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                color={starSettings[index % starSettings.length].color}
              />
            </div>
          ))}
        </div>

        <div className="d-flex mt-2">
          <i
            className={`fas fa-frown ${styles.emotion} ${styles.negative}`}
          ></i>
          <i className={`fas fa-meh ${styles.emotion} ${styles.neutral}`}></i>
          <i
            className={`fas fa-smile ${styles.emotion} ${styles.positive}`}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default StarRating;
